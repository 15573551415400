<template>
  <div class="column">
    <div class="s-card wrapper-copy"><!---->
      <div>
        <div class="page-content-inner">
          <div class="s-card">
            <div class="field columns is-multiline is-mobile">
              <div class="control column" data-v-61d0f824="">
                <button type="button" class="button is-fullwidth button VButton is-disabled is-fullwidth"
                        aria-hidden="false" data-v-61d0f824-s="" disabled=""><span>House weights</span></button><!---->
                <!----></div>
              <div class="control column" data-v-61d0f824="">
                <button type="button" class="button is-fullwidth button VButton is-fullwidth" aria-hidden="false"
                        data-v-61d0f824-s=""><span>Calc Exe</span></button><!----><!----></div>
              <div class="control column" data-v-61d0f824="">
                <button type="button" class="button is-fullwidth button VButton is-fullwidth" aria-hidden="false"
                        data-v-61d0f824-s=""><span><span>Build</span><!----></span></button><!----><!----></div>
              <div class="control column" data-v-61d0f824="">
                <button type="button"
                        class="button is-fullwidth is-warning is-outlined button VButton is-fullwidth is-warning is-outlined"
                        aria-hidden="false" data-v-61d0f824-s=""><span>Share </span></button><!----><!----></div>
              <div class="control column is-9" data-v-61d0f824=""><textarea class="textarea" rows="8"
                                                                            placeholder="Copy strategy data..">ERM3	-4
ERU3	-43
ERZ3	-43
ERH4	-36
ERM4	-43
ERU4	-43
ERZ4	-24
ERH5	236</textarea>
                <button type="button" class="button is-fullwidth button VButton is-fullwidth" aria-hidden="false"
                        data-v-61d0f824-s=""><span><span>Copy IN</span></span></button><!----><!----></div>
              <div class="control" data-v-61d0f824=""><label class="checkbox is-outlined is-info"
                                                             data-v-61d0f824-s=""><input type="checkbox"
                                                                                         data-v-61d0f824-s=""><span
                  data-v-61d0f824-s=""></span> is yield </label><!----><!----></div>
              <div class="control" data-v-61d0f824="">
                <div class="switch-block" data-v-61d0f824-s=""><label class="form-switch is-info"><input type="checkbox"
                                                                                                         class="is-switch"><i
                    aria-hidden="true"></i></label>
                  <div class="text"><label><span>Spot/Forward</span></label></div>
                </div><!----><!----></div>
            </div>
          </div>
          <div class="s-card mt-3">
            <div class="field"><label class="label">Name</label>
              <div class="field-body">
                <div class="field has-addons"><p class="control is-expanded"><input class="input" type="text"
                                                                                    name="strat_name"
                                                                                    value="Demo Strip"></p>
                  <div class="control">
                    <button class="button is-white is-outlined"> Generate name</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-container form-product-line">
              <table class="table is-hoverable is-fullwidth">
                <thead>
                <tr>
                  <th scope="col">Key</th>
                  <th scope="col">Product</th>
                  <th scope="col">Prc ratio</th>
                  <th scope="col">Exe ratio</th>
                  <th scope="col">Del</th>
                </tr>
                </thead>
                <tbody>
                <tr class="product-line has-background-danger">
                  <td>
                    <div class="field"><p class="control is-expanded"><input type="radio" name="is_key_contract" checked></p>
                    </div>
                  </td>
                  <td>
                    <div class="field mb-0">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select name="product" data-v-61d0f824-s="">
                          <option value="" data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">3Y</option>
                          <option data-v-61d0f824-s="">BTS</option>
                          <option data-v-61d0f824-s="">BTSBTS</option>
                          <option data-v-61d0f824-s="">CT10</option>
                          <option data-v-61d0f824-s="">CT2</option>
                          <option data-v-61d0f824-s="">CT20</option>
                          <option data-v-61d0f824-s="">CT3</option>
                          <option data-v-61d0f824-s="">CT30</option>
                          <option data-v-61d0f824-s="">CT5</option>
                          <option data-v-61d0f824-s="">CT7</option>
                          <option data-v-61d0f824-s="">DU</option>
                          <option data-v-61d0f824-s="">DUDU</option>
                          <option data-v-61d0f824-s="">ED</option>
                          <option data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">ER2YM3</option>
                          <option data-v-61d0f824-s="">ER3YM3</option>
                          <option data-v-61d0f824-s="">ER4YM3</option>
                          <option data-v-61d0f824-s="">ER5YM3</option>
                          <option data-v-61d0f824-s="">FF</option>
                          <option data-v-61d0f824-s="">FV</option>
                          <option data-v-61d0f824-s="">FVFV</option>
                          <option data-v-61d0f824-s="">G M3</option>
                          <option data-v-61d0f824-s="">G U3</option>
                          <option data-v-61d0f824-s="">IK</option>
                          <option data-v-61d0f824-s="">OAT</option>
                          <option data-v-61d0f824-s="">OE</option>
                          <option data-v-61d0f824-s="">OEOE</option>
                          <option data-v-61d0f824-s="">RX</option>
                          <option data-v-61d0f824-s="">SER</option>
                          <option data-v-61d0f824-s="">SFR</option>
                          <option data-v-61d0f824-s="">SFR1Y</option>
                          <option data-v-61d0f824-s="">SFR2Y</option>
                          <option data-v-61d0f824-s="">SFR3Y</option>
                          <option data-v-61d0f824-s="">SFR4Y</option>
                          <option data-v-61d0f824-s="">SFR5Y</option>
                          <option data-v-61d0f824-s="">TU</option>
                          <option data-v-61d0f824-s="">TUTU</option>
                          <option data-v-61d0f824-s="">TY</option>
                          <option data-v-61d0f824-s="">TYTY</option>
                          <option data-v-61d0f824-s="">US</option>
                          <option data-v-61d0f824-s="">USUS</option>
                          <option data-v-61d0f824-s="">UXY</option>
                          <option data-v-61d0f824-s="">UXYUXY</option>
                          <option data-v-61d0f824-s="">WN</option>
                          <option data-v-61d0f824-s="">WNWN</option>
                        </select></div><!----><!----></div>
                    </div>
                    <div class="field">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select class="mt-1" name="product"
                                                                         data-v-61d0f824-s="">
                          <option value="584" data-v-61d0f824-s="">M3</option>
                          <option value="548" data-v-61d0f824-s="">U3</option>
                          <option value="586" data-v-61d0f824-s="">Z3</option>
                          <option value="587" data-v-61d0f824-s="">H4</option>
                          <option value="588" data-v-61d0f824-s="">M4</option>
                          <option value="530" data-v-61d0f824-s="">U4</option>
                          <option value="590" data-v-61d0f824-s="">Z4</option>
                          <option value="591" data-v-61d0f824-s="">H5</option>
                          <option value="592" data-v-61d0f824-s="">M5</option>
                          <option value="593" data-v-61d0f824-s="">U5</option>
                          <option value="594" data-v-61d0f824-s="">Z5</option>
                          <option value="595" data-v-61d0f824-s="">H6</option>
                          <option value="596" data-v-61d0f824-s="">M6</option>
                          <option value="597" data-v-61d0f824-s="">U6</option>
                          <option value="598" data-v-61d0f824-s="">Z6</option>
                          <option value="599" data-v-61d0f824-s="">H7</option>
                          <option value="600" data-v-61d0f824-s="">M7</option>
                          <option value="601" data-v-61d0f824-s="">U7</option>
                          <option value="603" data-v-61d0f824-s="">H8</option>
                          <option value="604" data-v-61d0f824-s="">M8</option>
                          <option value="605" data-v-61d0f824-s="">U8</option>
                          <option value="602" data-v-61d0f824-s="">Z7</option>
                        </select></div><!----><!----></div>
                    </div><!----></td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="price_ratio" value="0"></p><!----></div>
                  </td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="trade_ratio" value="-4"></p></div>
                  </td>
                  <td>
                    <button class="button"><i class="fas fa-trash"></i></button>
                  </td>
                </tr>
                <tr class="product-line has-background-danger">
                  <td>
                    <div class="field"><p class="control is-expanded"><input type="radio" name="is_key_contract"></p>
                    </div>
                  </td>
                  <td>
                    <div class="field mb-0">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select name="product" data-v-61d0f824-s="">
                          <option value="" data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">3Y</option>
                          <option data-v-61d0f824-s="">BTS</option>
                          <option data-v-61d0f824-s="">BTSBTS</option>
                          <option data-v-61d0f824-s="">CT10</option>
                          <option data-v-61d0f824-s="">CT2</option>
                          <option data-v-61d0f824-s="">CT20</option>
                          <option data-v-61d0f824-s="">CT3</option>
                          <option data-v-61d0f824-s="">CT30</option>
                          <option data-v-61d0f824-s="">CT5</option>
                          <option data-v-61d0f824-s="">CT7</option>
                          <option data-v-61d0f824-s="">DU</option>
                          <option data-v-61d0f824-s="">DUDU</option>
                          <option data-v-61d0f824-s="">ED</option>
                          <option data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">ER2YM3</option>
                          <option data-v-61d0f824-s="">ER3YM3</option>
                          <option data-v-61d0f824-s="">ER4YM3</option>
                          <option data-v-61d0f824-s="">ER5YM3</option>
                          <option data-v-61d0f824-s="">FF</option>
                          <option data-v-61d0f824-s="">FV</option>
                          <option data-v-61d0f824-s="">FVFV</option>
                          <option data-v-61d0f824-s="">G M3</option>
                          <option data-v-61d0f824-s="">G U3</option>
                          <option data-v-61d0f824-s="">IK</option>
                          <option data-v-61d0f824-s="">OAT</option>
                          <option data-v-61d0f824-s="">OE</option>
                          <option data-v-61d0f824-s="">OEOE</option>
                          <option data-v-61d0f824-s="">RX</option>
                          <option data-v-61d0f824-s="">SER</option>
                          <option data-v-61d0f824-s="">SFR</option>
                          <option data-v-61d0f824-s="">SFR1Y</option>
                          <option data-v-61d0f824-s="">SFR2Y</option>
                          <option data-v-61d0f824-s="">SFR3Y</option>
                          <option data-v-61d0f824-s="">SFR4Y</option>
                          <option data-v-61d0f824-s="">SFR5Y</option>
                          <option data-v-61d0f824-s="">TU</option>
                          <option data-v-61d0f824-s="">TUTU</option>
                          <option data-v-61d0f824-s="">TY</option>
                          <option data-v-61d0f824-s="">TYTY</option>
                          <option data-v-61d0f824-s="">US</option>
                          <option data-v-61d0f824-s="">USUS</option>
                          <option data-v-61d0f824-s="">UXY</option>
                          <option data-v-61d0f824-s="">UXYUXY</option>
                          <option data-v-61d0f824-s="">WN</option>
                          <option data-v-61d0f824-s="">WNWN</option>
                        </select></div><!----><!----></div>
                    </div>
                    <div class="field">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select class="mt-1" name="product"
                                                                         data-v-61d0f824-s="">
                          <option value="584" data-v-61d0f824-s="">U3</option>
                          <option value="584" data-v-61d0f824-s="">M3</option>
                          <option value="548" data-v-61d0f824-s="">U3</option>
                          <option value="586" data-v-61d0f824-s="">Z3</option>
                          <option value="587" data-v-61d0f824-s="">H4</option>
                          <option value="588" data-v-61d0f824-s="">M4</option>
                          <option value="530" data-v-61d0f824-s="">U4</option>
                          <option value="590" data-v-61d0f824-s="">Z4</option>
                          <option value="591" data-v-61d0f824-s="">H5</option>
                          <option value="592" data-v-61d0f824-s="">M5</option>
                          <option value="593" data-v-61d0f824-s="">U5</option>
                          <option value="594" data-v-61d0f824-s="">Z5</option>
                          <option value="595" data-v-61d0f824-s="">H6</option>
                          <option value="596" data-v-61d0f824-s="">M6</option>
                          <option value="597" data-v-61d0f824-s="">U6</option>
                          <option value="598" data-v-61d0f824-s="">Z6</option>
                          <option value="599" data-v-61d0f824-s="">H7</option>
                          <option value="600" data-v-61d0f824-s="">M7</option>
                          <option value="601" data-v-61d0f824-s="">U7</option>
                          <option value="603" data-v-61d0f824-s="">H8</option>
                          <option value="604" data-v-61d0f824-s="">M8</option>
                          <option value="605" data-v-61d0f824-s="">U8</option>
                          <option value="602" data-v-61d0f824-s="">Z7</option>
                        </select></div><!----><!----></div>
                    </div><!----></td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="price_ratio" value="0"></p><!----></div>
                  </td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="trade_ratio" value="-43"></p></div>
                  </td>
                  <td>
                    <button class="button"><i class="fas fa-trash"></i></button>
                  </td>
                </tr>
                <tr class="product-line has-background-danger">
                  <td>
                    <div class="field"><p class="control is-expanded"><input type="radio" name="is_key_contract"></p>
                    </div>
                  </td>
                  <td>
                    <div class="field mb-0">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select name="product" data-v-61d0f824-s="">
                          <option value="" data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">3Y</option>
                          <option data-v-61d0f824-s="">BTS</option>
                          <option data-v-61d0f824-s="">BTSBTS</option>
                          <option data-v-61d0f824-s="">CT10</option>
                          <option data-v-61d0f824-s="">CT2</option>
                          <option data-v-61d0f824-s="">CT20</option>
                          <option data-v-61d0f824-s="">CT3</option>
                          <option data-v-61d0f824-s="">CT30</option>
                          <option data-v-61d0f824-s="">CT5</option>
                          <option data-v-61d0f824-s="">CT7</option>
                          <option data-v-61d0f824-s="">DU</option>
                          <option data-v-61d0f824-s="">DUDU</option>
                          <option data-v-61d0f824-s="">ED</option>
                          <option data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">ER2YM3</option>
                          <option data-v-61d0f824-s="">ER3YM3</option>
                          <option data-v-61d0f824-s="">ER4YM3</option>
                          <option data-v-61d0f824-s="">ER5YM3</option>
                          <option data-v-61d0f824-s="">FF</option>
                          <option data-v-61d0f824-s="">FV</option>
                          <option data-v-61d0f824-s="">FVFV</option>
                          <option data-v-61d0f824-s="">G M3</option>
                          <option data-v-61d0f824-s="">G U3</option>
                          <option data-v-61d0f824-s="">IK</option>
                          <option data-v-61d0f824-s="">OAT</option>
                          <option data-v-61d0f824-s="">OE</option>
                          <option data-v-61d0f824-s="">OEOE</option>
                          <option data-v-61d0f824-s="">RX</option>
                          <option data-v-61d0f824-s="">SER</option>
                          <option data-v-61d0f824-s="">SFR</option>
                          <option data-v-61d0f824-s="">SFR1Y</option>
                          <option data-v-61d0f824-s="">SFR2Y</option>
                          <option data-v-61d0f824-s="">SFR3Y</option>
                          <option data-v-61d0f824-s="">SFR4Y</option>
                          <option data-v-61d0f824-s="">SFR5Y</option>
                          <option data-v-61d0f824-s="">TU</option>
                          <option data-v-61d0f824-s="">TUTU</option>
                          <option data-v-61d0f824-s="">TY</option>
                          <option data-v-61d0f824-s="">TYTY</option>
                          <option data-v-61d0f824-s="">US</option>
                          <option data-v-61d0f824-s="">USUS</option>
                          <option data-v-61d0f824-s="">UXY</option>
                          <option data-v-61d0f824-s="">UXYUXY</option>
                          <option data-v-61d0f824-s="">WN</option>
                          <option data-v-61d0f824-s="">WNWN</option>
                        </select></div><!----><!----></div>
                    </div>
                    <div class="field">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select class="mt-1" name="product"
                                                                         data-v-61d0f824-s="">
                          <option value="584" data-v-61d0f824-s="">Z3</option>
                          <option value="584" data-v-61d0f824-s="">M3</option>
                          <option value="548" data-v-61d0f824-s="">U3</option>
                          <option value="586" data-v-61d0f824-s="">Z3</option>
                          <option value="587" data-v-61d0f824-s="">H4</option>
                          <option value="588" data-v-61d0f824-s="">M4</option>
                          <option value="530" data-v-61d0f824-s="">U4</option>
                          <option value="590" data-v-61d0f824-s="">Z4</option>
                          <option value="591" data-v-61d0f824-s="">H5</option>
                          <option value="592" data-v-61d0f824-s="">M5</option>
                          <option value="593" data-v-61d0f824-s="">U5</option>
                          <option value="594" data-v-61d0f824-s="">Z5</option>
                          <option value="595" data-v-61d0f824-s="">H6</option>
                          <option value="596" data-v-61d0f824-s="">M6</option>
                          <option value="597" data-v-61d0f824-s="">U6</option>
                          <option value="598" data-v-61d0f824-s="">Z6</option>
                          <option value="599" data-v-61d0f824-s="">H7</option>
                          <option value="600" data-v-61d0f824-s="">M7</option>
                          <option value="601" data-v-61d0f824-s="">U7</option>
                          <option value="603" data-v-61d0f824-s="">H8</option>
                          <option value="604" data-v-61d0f824-s="">M8</option>
                          <option value="605" data-v-61d0f824-s="">U8</option>
                          <option value="602" data-v-61d0f824-s="">Z7</option>
                        </select></div><!----><!----></div>
                    </div><!----></td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="price_ratio" value="0"></p><!----></div>
                  </td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="trade_ratio" value="-43"></p></div>
                  </td>
                  <td>
                    <button class="button"><i class="fas fa-trash"></i></button>
                  </td>
                </tr>
                <tr class="product-line has-background-danger">
                  <td>
                    <div class="field"><p class="control is-expanded"><input type="radio" name="is_key_contract"></p>
                    </div>
                  </td>
                  <td>
                    <div class="field mb-0">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select name="product" data-v-61d0f824-s="">
                          <option value="" data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">3Y</option>
                          <option data-v-61d0f824-s="">BTS</option>
                          <option data-v-61d0f824-s="">BTSBTS</option>
                          <option data-v-61d0f824-s="">CT10</option>
                          <option data-v-61d0f824-s="">CT2</option>
                          <option data-v-61d0f824-s="">CT20</option>
                          <option data-v-61d0f824-s="">CT3</option>
                          <option data-v-61d0f824-s="">CT30</option>
                          <option data-v-61d0f824-s="">CT5</option>
                          <option data-v-61d0f824-s="">CT7</option>
                          <option data-v-61d0f824-s="">DU</option>
                          <option data-v-61d0f824-s="">DUDU</option>
                          <option data-v-61d0f824-s="">ED</option>
                          <option data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">ER2YM3</option>
                          <option data-v-61d0f824-s="">ER3YM3</option>
                          <option data-v-61d0f824-s="">ER4YM3</option>
                          <option data-v-61d0f824-s="">ER5YM3</option>
                          <option data-v-61d0f824-s="">FF</option>
                          <option data-v-61d0f824-s="">FV</option>
                          <option data-v-61d0f824-s="">FVFV</option>
                          <option data-v-61d0f824-s="">G M3</option>
                          <option data-v-61d0f824-s="">G U3</option>
                          <option data-v-61d0f824-s="">IK</option>
                          <option data-v-61d0f824-s="">OAT</option>
                          <option data-v-61d0f824-s="">OE</option>
                          <option data-v-61d0f824-s="">OEOE</option>
                          <option data-v-61d0f824-s="">RX</option>
                          <option data-v-61d0f824-s="">SER</option>
                          <option data-v-61d0f824-s="">SFR</option>
                          <option data-v-61d0f824-s="">SFR1Y</option>
                          <option data-v-61d0f824-s="">SFR2Y</option>
                          <option data-v-61d0f824-s="">SFR3Y</option>
                          <option data-v-61d0f824-s="">SFR4Y</option>
                          <option data-v-61d0f824-s="">SFR5Y</option>
                          <option data-v-61d0f824-s="">TU</option>
                          <option data-v-61d0f824-s="">TUTU</option>
                          <option data-v-61d0f824-s="">TY</option>
                          <option data-v-61d0f824-s="">TYTY</option>
                          <option data-v-61d0f824-s="">US</option>
                          <option data-v-61d0f824-s="">USUS</option>
                          <option data-v-61d0f824-s="">UXY</option>
                          <option data-v-61d0f824-s="">UXYUXY</option>
                          <option data-v-61d0f824-s="">WN</option>
                          <option data-v-61d0f824-s="">WNWN</option>
                        </select></div><!----><!----></div>
                    </div>
                    <div class="field">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select class="mt-1" name="product"
                                                                         data-v-61d0f824-s="">
                          <option value="584" data-v-61d0f824-s="">H4</option>
                          <option value="584" data-v-61d0f824-s="">M3</option>
                          <option value="548" data-v-61d0f824-s="">U3</option>
                          <option value="586" data-v-61d0f824-s="">Z3</option>
                          <option value="587" data-v-61d0f824-s="">H4</option>
                          <option value="588" data-v-61d0f824-s="">M4</option>
                          <option value="530" data-v-61d0f824-s="">U4</option>
                          <option value="590" data-v-61d0f824-s="">Z4</option>
                          <option value="591" data-v-61d0f824-s="">H5</option>
                          <option value="592" data-v-61d0f824-s="">M5</option>
                          <option value="593" data-v-61d0f824-s="">U5</option>
                          <option value="594" data-v-61d0f824-s="">Z5</option>
                          <option value="595" data-v-61d0f824-s="">H6</option>
                          <option value="596" data-v-61d0f824-s="">M6</option>
                          <option value="597" data-v-61d0f824-s="">U6</option>
                          <option value="598" data-v-61d0f824-s="">Z6</option>
                          <option value="599" data-v-61d0f824-s="">H7</option>
                          <option value="600" data-v-61d0f824-s="">M7</option>
                          <option value="601" data-v-61d0f824-s="">U7</option>
                          <option value="603" data-v-61d0f824-s="">H8</option>
                          <option value="604" data-v-61d0f824-s="">M8</option>
                          <option value="605" data-v-61d0f824-s="">U8</option>
                          <option value="602" data-v-61d0f824-s="">Z7</option>
                        </select></div><!----><!----></div>
                    </div><!----></td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="price_ratio" value="0"></p><!----></div>
                  </td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="trade_ratio" value="-36"></p></div>
                  </td>
                  <td>
                    <button class="button"><i class="fas fa-trash"></i></button>
                  </td>
                </tr>
                <tr class="product-line has-background-danger">
                  <td>
                    <div class="field"><p class="control is-expanded"><input type="radio" name="is_key_contract"></p>
                    </div>
                  </td>
                  <td>
                    <div class="field mb-0">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select name="product" data-v-61d0f824-s="">
                          <option value="" data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">3Y</option>
                          <option data-v-61d0f824-s="">BTS</option>
                          <option data-v-61d0f824-s="">BTSBTS</option>
                          <option data-v-61d0f824-s="">CT10</option>
                          <option data-v-61d0f824-s="">CT2</option>
                          <option data-v-61d0f824-s="">CT20</option>
                          <option data-v-61d0f824-s="">CT3</option>
                          <option data-v-61d0f824-s="">CT30</option>
                          <option data-v-61d0f824-s="">CT5</option>
                          <option data-v-61d0f824-s="">CT7</option>
                          <option data-v-61d0f824-s="">DU</option>
                          <option data-v-61d0f824-s="">DUDU</option>
                          <option data-v-61d0f824-s="">ED</option>
                          <option data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">ER2YM3</option>
                          <option data-v-61d0f824-s="">ER3YM3</option>
                          <option data-v-61d0f824-s="">ER4YM3</option>
                          <option data-v-61d0f824-s="">ER5YM3</option>
                          <option data-v-61d0f824-s="">FF</option>
                          <option data-v-61d0f824-s="">FV</option>
                          <option data-v-61d0f824-s="">FVFV</option>
                          <option data-v-61d0f824-s="">G M3</option>
                          <option data-v-61d0f824-s="">G U3</option>
                          <option data-v-61d0f824-s="">IK</option>
                          <option data-v-61d0f824-s="">OAT</option>
                          <option data-v-61d0f824-s="">OE</option>
                          <option data-v-61d0f824-s="">OEOE</option>
                          <option data-v-61d0f824-s="">RX</option>
                          <option data-v-61d0f824-s="">SER</option>
                          <option data-v-61d0f824-s="">SFR</option>
                          <option data-v-61d0f824-s="">SFR1Y</option>
                          <option data-v-61d0f824-s="">SFR2Y</option>
                          <option data-v-61d0f824-s="">SFR3Y</option>
                          <option data-v-61d0f824-s="">SFR4Y</option>
                          <option data-v-61d0f824-s="">SFR5Y</option>
                          <option data-v-61d0f824-s="">TU</option>
                          <option data-v-61d0f824-s="">TUTU</option>
                          <option data-v-61d0f824-s="">TY</option>
                          <option data-v-61d0f824-s="">TYTY</option>
                          <option data-v-61d0f824-s="">US</option>
                          <option data-v-61d0f824-s="">USUS</option>
                          <option data-v-61d0f824-s="">UXY</option>
                          <option data-v-61d0f824-s="">UXYUXY</option>
                          <option data-v-61d0f824-s="">WN</option>
                          <option data-v-61d0f824-s="">WNWN</option>
                        </select></div><!----><!----></div>
                    </div>
                    <div class="field">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select class="mt-1" name="product"
                                                                         data-v-61d0f824-s="">
                          <option value="584" data-v-61d0f824-s="">M4</option>
                          <option value="584" data-v-61d0f824-s="">M3</option>
                          <option value="548" data-v-61d0f824-s="">U3</option>
                          <option value="586" data-v-61d0f824-s="">Z3</option>
                          <option value="587" data-v-61d0f824-s="">H4</option>
                          <option value="588" data-v-61d0f824-s="">M4</option>
                          <option value="530" data-v-61d0f824-s="">U4</option>
                          <option value="590" data-v-61d0f824-s="">Z4</option>
                          <option value="591" data-v-61d0f824-s="">H5</option>
                          <option value="592" data-v-61d0f824-s="">M5</option>
                          <option value="593" data-v-61d0f824-s="">U5</option>
                          <option value="594" data-v-61d0f824-s="">Z5</option>
                          <option value="595" data-v-61d0f824-s="">H6</option>
                          <option value="596" data-v-61d0f824-s="">M6</option>
                          <option value="597" data-v-61d0f824-s="">U6</option>
                          <option value="598" data-v-61d0f824-s="">Z6</option>
                          <option value="599" data-v-61d0f824-s="">H7</option>
                          <option value="600" data-v-61d0f824-s="">M7</option>
                          <option value="601" data-v-61d0f824-s="">U7</option>
                          <option value="603" data-v-61d0f824-s="">H8</option>
                          <option value="604" data-v-61d0f824-s="">M8</option>
                          <option value="605" data-v-61d0f824-s="">U8</option>
                          <option value="602" data-v-61d0f824-s="">Z7</option>
                        </select></div><!----><!----></div>
                    </div><!----></td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="price_ratio" value="0"></p><!----></div>
                  </td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="trade_ratio" value="-43"></p></div>
                  </td>
                  <td>
                    <button class="button"><i class="fas fa-trash"></i></button>
                  </td>
                </tr>
                <tr class="product-line has-background-danger">
                  <td>
                    <div class="field"><p class="control is-expanded"><input type="radio" name="is_key_contract"></p>
                    </div>
                  </td>
                  <td>
                    <div class="field mb-0">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select name="product" data-v-61d0f824-s="">
                          <option value="" data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">3Y</option>
                          <option data-v-61d0f824-s="">BTS</option>
                          <option data-v-61d0f824-s="">BTSBTS</option>
                          <option data-v-61d0f824-s="">CT10</option>
                          <option data-v-61d0f824-s="">CT2</option>
                          <option data-v-61d0f824-s="">CT20</option>
                          <option data-v-61d0f824-s="">CT3</option>
                          <option data-v-61d0f824-s="">CT30</option>
                          <option data-v-61d0f824-s="">CT5</option>
                          <option data-v-61d0f824-s="">CT7</option>
                          <option data-v-61d0f824-s="">DU</option>
                          <option data-v-61d0f824-s="">DUDU</option>
                          <option data-v-61d0f824-s="">ED</option>
                          <option data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">ER2YM3</option>
                          <option data-v-61d0f824-s="">ER3YM3</option>
                          <option data-v-61d0f824-s="">ER4YM3</option>
                          <option data-v-61d0f824-s="">ER5YM3</option>
                          <option data-v-61d0f824-s="">FF</option>
                          <option data-v-61d0f824-s="">FV</option>
                          <option data-v-61d0f824-s="">FVFV</option>
                          <option data-v-61d0f824-s="">G M3</option>
                          <option data-v-61d0f824-s="">G U3</option>
                          <option data-v-61d0f824-s="">IK</option>
                          <option data-v-61d0f824-s="">OAT</option>
                          <option data-v-61d0f824-s="">OE</option>
                          <option data-v-61d0f824-s="">OEOE</option>
                          <option data-v-61d0f824-s="">RX</option>
                          <option data-v-61d0f824-s="">SER</option>
                          <option data-v-61d0f824-s="">SFR</option>
                          <option data-v-61d0f824-s="">SFR1Y</option>
                          <option data-v-61d0f824-s="">SFR2Y</option>
                          <option data-v-61d0f824-s="">SFR3Y</option>
                          <option data-v-61d0f824-s="">SFR4Y</option>
                          <option data-v-61d0f824-s="">SFR5Y</option>
                          <option data-v-61d0f824-s="">TU</option>
                          <option data-v-61d0f824-s="">TUTU</option>
                          <option data-v-61d0f824-s="">TY</option>
                          <option data-v-61d0f824-s="">TYTY</option>
                          <option data-v-61d0f824-s="">US</option>
                          <option data-v-61d0f824-s="">USUS</option>
                          <option data-v-61d0f824-s="">UXY</option>
                          <option data-v-61d0f824-s="">UXYUXY</option>
                          <option data-v-61d0f824-s="">WN</option>
                          <option data-v-61d0f824-s="">WNWN</option>
                        </select></div><!----><!----></div>
                    </div>
                    <div class="field">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select class="mt-1" name="product"
                                                                         data-v-61d0f824-s="">
                          <option value="584" data-v-61d0f824-s="">U4</option>
                          <option value="584" data-v-61d0f824-s="">M3</option>
                          <option value="548" data-v-61d0f824-s="">U3</option>
                          <option value="586" data-v-61d0f824-s="">Z3</option>
                          <option value="587" data-v-61d0f824-s="">H4</option>
                          <option value="588" data-v-61d0f824-s="">M4</option>
                          <option value="530" data-v-61d0f824-s="">U4</option>
                          <option value="590" data-v-61d0f824-s="">Z4</option>
                          <option value="591" data-v-61d0f824-s="">H5</option>
                          <option value="592" data-v-61d0f824-s="">M5</option>
                          <option value="593" data-v-61d0f824-s="">U5</option>
                          <option value="594" data-v-61d0f824-s="">Z5</option>
                          <option value="595" data-v-61d0f824-s="">H6</option>
                          <option value="596" data-v-61d0f824-s="">M6</option>
                          <option value="597" data-v-61d0f824-s="">U6</option>
                          <option value="598" data-v-61d0f824-s="">Z6</option>
                          <option value="599" data-v-61d0f824-s="">H7</option>
                          <option value="600" data-v-61d0f824-s="">M7</option>
                          <option value="601" data-v-61d0f824-s="">U7</option>
                          <option value="603" data-v-61d0f824-s="">H8</option>
                          <option value="604" data-v-61d0f824-s="">M8</option>
                          <option value="605" data-v-61d0f824-s="">U8</option>
                          <option value="602" data-v-61d0f824-s="">Z7</option>
                        </select></div><!----><!----></div>
                    </div><!----></td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="price_ratio" value="0"></p><!----></div>
                  </td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="trade_ratio" value="-43"></p></div>
                  </td>
                  <td>
                    <button class="button"><i class="fas fa-trash"></i></button>
                  </td>
                </tr>
                <tr class="product-line has-background-danger">
                  <td>
                    <div class="field"><p class="control is-expanded"><input type="radio" name="is_key_contract"></p>
                    </div>
                  </td>
                  <td>
                    <div class="field mb-0">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select name="product" data-v-61d0f824-s="">
                          <option value="" data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">3Y</option>
                          <option data-v-61d0f824-s="">BTS</option>
                          <option data-v-61d0f824-s="">BTSBTS</option>
                          <option data-v-61d0f824-s="">CT10</option>
                          <option data-v-61d0f824-s="">CT2</option>
                          <option data-v-61d0f824-s="">CT20</option>
                          <option data-v-61d0f824-s="">CT3</option>
                          <option data-v-61d0f824-s="">CT30</option>
                          <option data-v-61d0f824-s="">CT5</option>
                          <option data-v-61d0f824-s="">CT7</option>
                          <option data-v-61d0f824-s="">DU</option>
                          <option data-v-61d0f824-s="">DUDU</option>
                          <option data-v-61d0f824-s="">ED</option>
                          <option data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">ER2YM3</option>
                          <option data-v-61d0f824-s="">ER3YM3</option>
                          <option data-v-61d0f824-s="">ER4YM3</option>
                          <option data-v-61d0f824-s="">ER5YM3</option>
                          <option data-v-61d0f824-s="">FF</option>
                          <option data-v-61d0f824-s="">FV</option>
                          <option data-v-61d0f824-s="">FVFV</option>
                          <option data-v-61d0f824-s="">G M3</option>
                          <option data-v-61d0f824-s="">G U3</option>
                          <option data-v-61d0f824-s="">IK</option>
                          <option data-v-61d0f824-s="">OAT</option>
                          <option data-v-61d0f824-s="">OE</option>
                          <option data-v-61d0f824-s="">OEOE</option>
                          <option data-v-61d0f824-s="">RX</option>
                          <option data-v-61d0f824-s="">SER</option>
                          <option data-v-61d0f824-s="">SFR</option>
                          <option data-v-61d0f824-s="">SFR1Y</option>
                          <option data-v-61d0f824-s="">SFR2Y</option>
                          <option data-v-61d0f824-s="">SFR3Y</option>
                          <option data-v-61d0f824-s="">SFR4Y</option>
                          <option data-v-61d0f824-s="">SFR5Y</option>
                          <option data-v-61d0f824-s="">TU</option>
                          <option data-v-61d0f824-s="">TUTU</option>
                          <option data-v-61d0f824-s="">TY</option>
                          <option data-v-61d0f824-s="">TYTY</option>
                          <option data-v-61d0f824-s="">US</option>
                          <option data-v-61d0f824-s="">USUS</option>
                          <option data-v-61d0f824-s="">UXY</option>
                          <option data-v-61d0f824-s="">UXYUXY</option>
                          <option data-v-61d0f824-s="">WN</option>
                          <option data-v-61d0f824-s="">WNWN</option>
                        </select></div><!----><!----></div>
                    </div>
                    <div class="field">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select class="mt-1" name="product"
                                                                         data-v-61d0f824-s="">
                          <option value="584" data-v-61d0f824-s="">Z4</option>
                          <option value="584" data-v-61d0f824-s="">M3</option>
                          <option value="548" data-v-61d0f824-s="">U3</option>
                          <option value="586" data-v-61d0f824-s="">Z3</option>
                          <option value="587" data-v-61d0f824-s="">H4</option>
                          <option value="588" data-v-61d0f824-s="">M4</option>
                          <option value="530" data-v-61d0f824-s="">U4</option>
                          <option value="590" data-v-61d0f824-s="">Z4</option>
                          <option value="591" data-v-61d0f824-s="">H5</option>
                          <option value="592" data-v-61d0f824-s="">M5</option>
                          <option value="593" data-v-61d0f824-s="">U5</option>
                          <option value="594" data-v-61d0f824-s="">Z5</option>
                          <option value="595" data-v-61d0f824-s="">H6</option>
                          <option value="596" data-v-61d0f824-s="">M6</option>
                          <option value="597" data-v-61d0f824-s="">U6</option>
                          <option value="598" data-v-61d0f824-s="">Z6</option>
                          <option value="599" data-v-61d0f824-s="">H7</option>
                          <option value="600" data-v-61d0f824-s="">M7</option>
                          <option value="601" data-v-61d0f824-s="">U7</option>
                          <option value="603" data-v-61d0f824-s="">H8</option>
                          <option value="604" data-v-61d0f824-s="">M8</option>
                          <option value="605" data-v-61d0f824-s="">U8</option>
                          <option value="602" data-v-61d0f824-s="">Z7</option>
                        </select></div><!----><!----></div>
                    </div><!----></td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="price_ratio" value="0"></p><!----></div>
                  </td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="trade_ratio" value="-24"></p></div>
                  </td>
                  <td>
                    <button class="button"><i class="fas fa-trash"></i></button>
                  </td>
                </tr>
                <tr class="product-line has-background-info">
                  <td>
                    <div class="field"><p class="control is-expanded"><input type="radio" name="is_key_contract"></p>
                    </div>
                  </td>
                  <td>
                    <div class="field mb-0">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select name="product" data-v-61d0f824-s="">
                          <option value="" data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">3Y</option>
                          <option data-v-61d0f824-s="">BTS</option>
                          <option data-v-61d0f824-s="">BTSBTS</option>
                          <option data-v-61d0f824-s="">CT10</option>
                          <option data-v-61d0f824-s="">CT2</option>
                          <option data-v-61d0f824-s="">CT20</option>
                          <option data-v-61d0f824-s="">CT3</option>
                          <option data-v-61d0f824-s="">CT30</option>
                          <option data-v-61d0f824-s="">CT5</option>
                          <option data-v-61d0f824-s="">CT7</option>
                          <option data-v-61d0f824-s="">DU</option>
                          <option data-v-61d0f824-s="">DUDU</option>
                          <option data-v-61d0f824-s="">ED</option>
                          <option data-v-61d0f824-s="">ER</option>
                          <option data-v-61d0f824-s="">ER2YM3</option>
                          <option data-v-61d0f824-s="">ER3YM3</option>
                          <option data-v-61d0f824-s="">ER4YM3</option>
                          <option data-v-61d0f824-s="">ER5YM3</option>
                          <option data-v-61d0f824-s="">FF</option>
                          <option data-v-61d0f824-s="">FV</option>
                          <option data-v-61d0f824-s="">FVFV</option>
                          <option data-v-61d0f824-s="">G M3</option>
                          <option data-v-61d0f824-s="">G U3</option>
                          <option data-v-61d0f824-s="">IK</option>
                          <option data-v-61d0f824-s="">OAT</option>
                          <option data-v-61d0f824-s="">OE</option>
                          <option data-v-61d0f824-s="">OEOE</option>
                          <option data-v-61d0f824-s="">RX</option>
                          <option data-v-61d0f824-s="">SER</option>
                          <option data-v-61d0f824-s="">SFR</option>
                          <option data-v-61d0f824-s="">SFR1Y</option>
                          <option data-v-61d0f824-s="">SFR2Y</option>
                          <option data-v-61d0f824-s="">SFR3Y</option>
                          <option data-v-61d0f824-s="">SFR4Y</option>
                          <option data-v-61d0f824-s="">SFR5Y</option>
                          <option data-v-61d0f824-s="">TU</option>
                          <option data-v-61d0f824-s="">TUTU</option>
                          <option data-v-61d0f824-s="">TY</option>
                          <option data-v-61d0f824-s="">TYTY</option>
                          <option data-v-61d0f824-s="">US</option>
                          <option data-v-61d0f824-s="">USUS</option>
                          <option data-v-61d0f824-s="">UXY</option>
                          <option data-v-61d0f824-s="">UXYUXY</option>
                          <option data-v-61d0f824-s="">WN</option>
                          <option data-v-61d0f824-s="">WNWN</option>
                        </select></div><!----><!----></div>
                    </div>
                    <div class="field">
                      <div class="control is-expanded" data-v-61d0f824="">
                        <div class="select" data-v-61d0f824-s=""><select class="mt-1" name="product"
                                                                         data-v-61d0f824-s="">
                          <option value="584" data-v-61d0f824-s="">H5</option>
                          <option value="584" data-v-61d0f824-s="">M3</option>
                          <option value="548" data-v-61d0f824-s="">U3</option>
                          <option value="586" data-v-61d0f824-s="">Z3</option>
                          <option value="587" data-v-61d0f824-s="">H4</option>
                          <option value="588" data-v-61d0f824-s="">M4</option>
                          <option value="530" data-v-61d0f824-s="">U4</option>
                          <option value="590" data-v-61d0f824-s="">Z4</option>
                          <option value="591" data-v-61d0f824-s="">H5</option>
                          <option value="592" data-v-61d0f824-s="">M5</option>
                          <option value="593" data-v-61d0f824-s="">U5</option>
                          <option value="594" data-v-61d0f824-s="">Z5</option>
                          <option value="595" data-v-61d0f824-s="">H6</option>
                          <option value="596" data-v-61d0f824-s="">M6</option>
                          <option value="597" data-v-61d0f824-s="">U6</option>
                          <option value="598" data-v-61d0f824-s="">Z6</option>
                          <option value="599" data-v-61d0f824-s="">H7</option>
                          <option value="600" data-v-61d0f824-s="">M7</option>
                          <option value="601" data-v-61d0f824-s="">U7</option>
                          <option value="603" data-v-61d0f824-s="">H8</option>
                          <option value="604" data-v-61d0f824-s="">M8</option>
                          <option value="605" data-v-61d0f824-s="">U8</option>
                          <option value="602" data-v-61d0f824-s="">Z7</option>
                        </select></div><!----><!----></div>
                    </div><!----></td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="price_ratio" value="0"></p><!----></div>
                  </td>
                  <td>
                    <div class="field"><p class="control is-expanded"><input class="input" type="number"
                                                                             name="trade_ratio" value="236"></p></div>
                  </td>
                  <td>
                    <button class="button"><i class="fas fa-trash"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
              <button class="button"><span class="icon"><i
                  class="fas fa-plus"></i></span><span>Add another Product</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
